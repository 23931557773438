<template>
  <p class="clearfix mb-0">
    <span
      class="float-md-left d-block d-md-inline-block mt-25"
      style="font-size:10px;"
    >
      <a href="https://tomaszkrawczyk.pl">TomaszKrawczyk.pl</a> - Wszelkie prawa zastrzeżone. <br>Wszelkie próby kopiowania treści ogłoszenia zostaną zgłoszone odpowiednim organom ścigania.</span>

    <span
      class="float-md-right d-none d-md-block"
      style="font-size:10px;"
    >Projekt i realizacja: <a href="https://thomasworks.eu">thomasworks.eu</a>
    </span>
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
  },
}
</script>
