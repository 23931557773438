import Vue from "vue";

const navig = [
  {
    title: 'Panel główny',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Moje kursy',
    route: 'my-courses',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Zamówienia',
    route: 'orders',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Ustawienia',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'Sklep',
    route: 'shop',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Koszyk',
    route: 'checkout',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Moje rabaty',
    route: 'discounts',
    icon: 'DollarSignIcon',
  },
]

if (Vue.prototype.$workbox === null) {
  navig.splice(4, 1)
  navig.splice(4, 1)
}

export default navig
